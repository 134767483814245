import { useNavigate, useParams } from '@remix-run/react';
import { useMemo, useState } from 'react';
import { Waypoint } from 'react-waypoint';
import useSWRImmutable from 'swr/immutable';

import {
  type DtoAIChatMessage,
  type DtoAIChatThread,
  type DtoAIChatThreadListResponse,
} from '@lp-lib/api-service-client/public';

import { useListLoader } from '../../../hooks/useListLoader';
import logger from '../../../logger/logger';
import { apiService } from '../../../services/api-service';
import { type ChatThreadSearchParams } from '../../../services/api-service/ai.chat.api';
import { DateUtils } from '../../../utils/date';
import { ErrorMessage } from '../../Game/GameCenter';
import { Loading } from '../../Loading';
import { parseStreamId } from '../../Session';
import { SwitcherControlled } from '../../Switcher';
import { AIChatParserUtils } from '../utils';

function ChatThreadItem(props: { chatThread: DtoAIChatThread }): JSX.Element {
  const { chatThread } = props;
  const navigate = useNavigate();
  return (
    <tr
      className='text-sms cursor-pointer'
      onClick={() =>
        navigate(`/admin/toolkit/ai-chat/threads/${chatThread.id}`)
      }
    >
      <td className='py-1.5'>{chatThread.bizLabel}</td>
      <td className='py-1.5'>{chatThread.messagesCount}</td>
      <td className='py-1.5'>
        {DateUtils.FormatDatetime(chatThread.createdAt)}
      </td>
    </tr>
  );
}

function PaginatedList(props: {
  searchParams?: ChatThreadSearchParams;
}): JSX.Element | null {
  const { searchParams } = props;
  const paginator = useMemo(
    () => apiService.aiChat.searchChatThreads(searchParams),
    [searchParams]
  );
  const { items, state, error, handleLoadMore, handleRetry } = useListLoader<
    DtoAIChatThreadListResponse,
    DtoAIChatThread
  >(paginator, (a, b) => a.id === b.id);

  const showEmptyMsg =
    state.isDone && !error && items.length === 0 && !paginator.hasMore();
  const canLoadMore = state.isDone && !error && paginator.hasMore();
  return (
    <div>
      <table className='w-full'>
        <thead>
          <tr className='text-left'>
            <th className='pb-3'>Conversation</th>
            <th className='pb-3'>Messages</th>
            <th className='pb-3'>Started Date/Time</th>
          </tr>
        </thead>
        <tbody>
          {items.map((i) => (
            <ChatThreadItem key={i.id} chatThread={i} />
          ))}
        </tbody>
      </table>
      <div>
        {state.isRunning && (
          <div className='flex items-center justify-center mt-50'>
            <Loading />
          </div>
        )}
        {error && (
          <div className='w-full flex items-center justify-center text-white mt-50'>
            <ErrorMessage
              text='Something went wrong'
              handleRetry={handleRetry}
            />
          </div>
        )}
        {showEmptyMsg && (
          <div className='w-full flex items-center justify-center text-white mt-50'>
            No Conversations.
          </div>
        )}
        {canLoadMore && (
          <Waypoint onEnter={handleLoadMore} fireOnRapidScroll>
            <div>&nbsp;</div>
          </Waypoint>
        )}
      </div>
    </div>
  );
}

export function ChatThreadList(): JSX.Element | null {
  const [streamId, setStreamId] = useState<string | null>(null);
  const sessionId = useMemo(() => {
    if (!streamId) return null;
    try {
      return parseStreamId(streamId).sessionId;
    } catch (error) {
      return null;
    }
  }, [streamId]);
  return (
    <div>
      <div className='mb-8 flex items-center justify-start gap-4'>
        <input
          className='field h-10 w-80 mb-0'
          placeholder='Stream/Session ID'
          onChange={(e) => setStreamId(e.target.value)}
        />
      </div>
      <PaginatedList searchParams={{ sessionId }} />
    </div>
  );
}

class MessageParser {
  private assistantMessageParser;
  constructor(
    thread: DtoAIChatThread,
    readonly log = logger.scoped('admin-ai-conversation')
  ) {
    this.assistantMessageParser = AIChatParserUtils.GetOutputParser(
      thread.promptTemplate,
      log
    );
  }

  parse(
    body: Record<string, unknown>,
    raw = false
  ): string | string[] | undefined {
    if (!AIChatParserUtils.IsOpenAIMessage(body) || raw) {
      return JSON.stringify(body);
    }
    if (!body.content) return;
    if (body.role === 'assistant') {
      return this.assistantMessageParser
        .parse(body.content)
        .dialogue.split('\n');
    }
    return body.content.split('\n');
  }
}

function ChatMessage(props: {
  message: DtoAIChatMessage;
  index: number;
  parser: MessageParser;
  raw: boolean;
}): JSX.Element {
  const { message, index, parser, raw } = props;
  const parsed = useMemo(
    () => parser.parse(message.body, raw),
    [message.body, parser, raw]
  );
  return (
    <div
      className={`p-4 flex flex-col gap-1 ${
        index % 2 === 0 ? 'bg-light-gray' : 'bg-dark-gray'
      }`}
    >
      <div className='text-sm italic'>{message.displayName}</div>
      {parsed === undefined || typeof parsed === 'string' ? (
        <div>{parsed ?? 'N/A'}</div>
      ) : (
        parsed.map((line, i) => <div key={i}>{line}</div>)
      )}
    </div>
  );
}

function ChatThreadMessageList(props: {
  thread: DtoAIChatThread;
  messages: DtoAIChatMessage[];
}): JSX.Element {
  const { thread, messages } = props;
  const [raw, setRaw] = useState(false);
  const parser = useMemo(() => new MessageParser(thread), [thread]);
  return (
    <div className='flex flex-col gap-2'>
      <div className='flex items-center justify-between gap-2'>
        <div className='text-2xl'>{thread.bizLabel}</div>
        <div className='flex justify-between items-center gap-2'>
          <span>Show Raw Data</span>
          <SwitcherControlled
            name='raw'
            checked={raw}
            onChange={(checked) => setRaw(checked)}
          />
        </div>
      </div>
      <div>
        {messages.map((m, i) => (
          <ChatMessage
            key={m.id}
            message={m}
            index={i}
            raw={raw}
            parser={parser}
          />
        ))}
      </div>
    </div>
  );
}

export function ChatThreadDetail(): JSX.Element | null {
  const { id } = useParams<'id'>();
  const { data, isLoading } = useSWRImmutable(
    id ? `/ai/chat/threads/${id}/messages` : null,
    async () => {
      if (!id) return null;
      const threadResp = await apiService.aiChat.getChatThreadById(id);
      const messagesResp = await apiService.aiChat.getMessagesByThreadId(id);
      return {
        thread: threadResp.data.chatThread,
        messages: messagesResp.data.chatMessages,
      };
    }
  );

  if (isLoading) return <Loading />;
  if (!data) return <div>Conversation not found</div>;
  return <ChatThreadMessageList {...data} />;
}
